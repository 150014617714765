<template>
  <div class="home">
    <v-btn-toggle v-model="myorder" color="primary" dense group>
      <v-btn :value="1" text>
        <v-icon>mdi-map-marker</v-icon>
        Nach Standort
      </v-btn>

      <v-btn :value="2" text>
        <v-icon>mdi-format-align-center</v-icon>
        Nach Bereich
      </v-btn>
    </v-btn-toggle>
    <v-card v-for="(plantis, i) in plantGroups" :key="i">
      <h4>{{ plantis.name }}</h4>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in plantis.pflanzen" :key="i">
          <v-expansion-panel-header>
            <v-row align="center" justify="center">
              <v-col cols="2">
                <v-checkbox
                  v-model="item.bearbeitet"
                  color="success"
                  hide-details
                  @click.native="changestate($event, item)"
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                {{ item.mengeAng }}
              </v-col>
              <v-col cols="8">
                {{ item.name }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel v-for="(pos, ii) in item.positions" :key="ii">
                <v-expansion-panel-header>
                  {{ pos.grundNam + " Beet " + pos.beet }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    {{
                      "Beet " +
                      pos.beet +
                      " " +
                      pos.section +
                      ", Reihe " +
                      pos.position
                    }}
                  </v-row>
                  <v-row>
                    {{ "Verfügbare Menge: " + pos.menge }}
                  </v-row>
                  <v-row>
                    {{ "Datum: " + pos.datum }}
                  </v-row>
                  <v-row>
                    {{ "Bem.: " + pos.bemerkung }}
                  </v-row>
                  <v-row>
                    <v-col sm="6" md="4" cols="3">
                      <v-icon medium @click="deleteId(pos)">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-col>
                    <v-col sm="6" md="4" cols="3">
                      <v-icon medium @click="editPosition(pos, item)">
                        mdi-pencil
                      </v-icon>
                    </v-col>
                    <v-col sm="6" md="4" cols="3">
                      <v-icon medium @click="gotoBeet(pos, item)">
                        mdi-map-marker
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <!-- <v-row align="center" justify="center" v-for="(item, i) in plants" :key="i">
      <v-col cols="1">
        <v-checkbox
          v-model="item.bearbeitet"
          color="success"
          hide-details
          @click="changestate(item)"
        ></v-checkbox>
      </v-col>
      <v-col cols="10" align="center" justify="center">
        <v-btn elevation="1" color="#e0f4ff">
          {{ item.name }}
        </v-btn>
      </v-col>
    </v-row> -->

    <v-card>
      <h4>Anzahl Pflanzen gesamt: {{ count }}</h4>
    </v-card>

    <v-btn
      v-if="bearbeitet"
      block
      elevation="2"
      color="#d1ffe3"
      @click="changestateAuftrag"
    >
      <v-icon>mdi-check </v-icon>
      Auftrag erledigt
    </v-btn>
    <v-btn
      v-else
      block
      elevation="2"
      color="#ffffff"
      @click="changestateAuftrag"
    >
      <v-icon>mdi-dots-horizontal </v-icon>
      Auftrag in bearbeitung
    </v-btn>
    <v-dialog v-model="posDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>{{ staude.name }}:</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="4">
                <p>Menge:</p>
              </v-col>
              <v-col sm="6" md="4" cols="8">
                <v-text-field
                  type="number"
                  :label="position.menge.toString()"
                  v-model="newPosition.menge"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newPosition.datum"
                      :label="position.datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newPosition.datum"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="3">
                <p>Bem.:</p>
              </v-col>
              <v-col sm="6" md="4" cols="9">
                <v-text-field
                  :label="position.bemerkung"
                  v-model="newPosition.bemerkung"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="posDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="savePosition"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "auftragsView",
  data: () => ({
    myorder: 2,
    name: null,
    bearbeitet: false,
    id: null,
    datum: null,
    plants: [],
    posDialog: false,
    count: null,
    plantGroups: [],
    staude: [],
    menuDate: false,
    stauDialog: false,
    newPosition: { name: "", bemerkung: "", menge: 0, datum: "", date: null },
    newStaude: [],
    position: { name: "", bemerkung: "", menge: 0, datum: "", date: null }
  }),

  methods: {
    prepPlantGroups() {
      this.plantGroups = [];
      var groups = [];
      this.count = 0;
      var keinePositionString = "nicht vorhanden";
      for (var pfl in this.plants) {
        var gr;
        this.count += parseInt(this.plants[pfl]["mengeAng"]);
        if (this.myorder == 2) {
          gr = this.plants[pfl]["paket"];
          if (groups.indexOf(gr) < 0) {
            groups.push(gr);
          }
        } else if (this.myorder == 1) {
          if (this.plants[pfl]["positions"].length == 0) {
            if (groups.indexOf(keinePositionString) < 0) {
              groups.push(keinePositionString);
            }
          } else {
            for (var pos in this.plants[pfl]["positions"]) {
              if (
                groups.indexOf(this.plants[pfl]["positions"][pos]["grundNam"]) <
                0
              ) {
                groups.push(this.plants[pfl]["positions"][pos]["grundNam"]);
              }
            }
          }
        }
      }

      this.plantGroups = [];
      for (var gr in groups) {
        var pflanzen = [];
        for (var pfl in this.plants) {
          if (this.myorder == 2) {
            if (this.plants[pfl]["paket"] == groups[gr]) {
              pflanzen.push(this.plants[pfl]);
            }
          } else if (this.myorder == 1) {
            if (this.plants[pfl]["positions"].length == 0) {
              if (groups[gr] == keinePositionString) {
                pflanzen.push(this.plants[pfl]);
              }
            } else {
              var addit = false;
              for (var pos in this.plants[pfl]["positions"]) {
                if (
                  this.plants[pfl]["positions"][pos]["grundNam"] == groups[gr]
                ) {
                  addit = true;
                }
              }
              if (addit) {
                pflanzen.push(this.plants[pfl]);
              }
            }
          }
        }
        this.plantGroups.push({ name: groups[gr], pflanzen: pflanzen });
      }
      //   console.log(groups);
      //   console.log(this.plantGroups);
    },
    gotoBeet(pos, item) {
      console.log(item)
      console.log(pos)
      confirm("Der Button funktioniert noch nicht")
      // this.$router.push({
      //   name: "beetView",
      //   params: {
      //     grundstuck: pos["grundID"],
      //     grundstuckName: pos["grundNam"],
      //     beet: pos["beet"],
      //     posID: pos["posID"],
      //   },
      // });
    },
    loadAuftrag() {
      this.plants = axios
        .get(
          this.$hostname +
            "/angebotLaden.php?AuftragsID=" +
            encodeURIComponent(this.id)
        )
        .then((response) => {
          this.plants = response.data;
          this.prepPlantGroups();
        });
    },
    changestate(evt, item) {
      evt.cancelBubble = true;

      axios
        .get(
          this.$hostname +
            "/changeStateAuftragsitem.php?ID=" +
            item["angPosID"] +
            "&state=" +
            item["bearbeitet"] * 1
        )
        .then((response) => console.log(response.data));
      //   console.log(item);
    },
    changestateAuftrag(item) {
      this.bearbeitet = !this.bearbeitet;
      axios
        .get(
          this.$hostname +
            "/changeStateAuftrag.php?ID=" +
            this.id +
            "&state=" +
            this.bearbeitet * 1
        )
        .then((response) => console.log(response.data));
      //   console.log(this.list);
    },
    editPosition(position, pfl) {
      // console.log(pfl.name);
      this.staude = pfl;
      this.position = { ...position };
      this.newPosition = { ...position };
      let myDate = new Date();
      const offset = myDate.getTimezoneOffset();
      myDate = new Date(myDate.getTime() - offset * 60 * 1000);
      this.newPosition.datum = myDate.toISOString().split("T")[0];
      this.posDialog = true;
    },
    savePlant() {
      this.stauDialog = false;
    },
    savePosition() {
      for (var i = 0; i < this.plants.length; i++) {
        var pl = this.plants[i];
        for (var i2 = 0; i2 < pl.positions.length; i2++) {
          var pos = pl.positions[i2];
          if (pos.posID == this.newPosition.posID) {
            this.plants[i].positions[i2] = { ...this.newPosition };
          }
        }
      }
      const params = new URLSearchParams();
      // console.log(this.newPosition);

      for (let key in this.newPosition) {
        params.append(key, this.newPosition[key]);
      }
      axios
        .post(this.$hostname + "/updatePosition.php", params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log(response.data);
        });
      this.posDialog = false;
    },
    showpflanzenRename(evt, item) {
      evt.cancelBubble = true;
      this.stauDialog = true;
    },
    deleteId(id) {
      if (confirm("Standort löschen?")) {
        // axios.post('http://localhost:80/stauden_backend_php/deletePosition.php', { posID: id})
        //   .then(response => console.log(response));
        // console.log(id);

        function asd(x){
          console.log(x)
        }

        axios
          .get(this.$hostname + "/deletePosition.php?posID=" + id["posID"])
          .then((response) => this.loadAuftrag());
        
      }
    },
  },

  beforeMount() {
    if (
      this.$route.params.hasOwnProperty("name") &&
      this.$route.params.hasOwnProperty("id")
    ) {
      this.name = this.$route.params.name;
      
      this.id = this.$route.params.id;
      this.datum = this.$route.params.datum;
      this.bearbeitet = this.$route.params.bearbeitet;
      this.loadAuftrag();
      
    } else {
      this.$router.push({
        name: "navigateAuftrag",
      });
    }
  },
  watch: {
    myorder: function () {
      this.prepPlantGroups();
    },
  },
};
</script>
