<template>
  <div>
    <v-row>
      <v-col cols="9">
        <v-banner elevation="0">{{ pflanze.name }} </v-banner>
      </v-col>

      <v-col cols="3">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Staude auswählen:</span>
            </v-card-title>
            <v-text-field
              placeholder="Search"
              v-model="searchString"
            ></v-text-field>
            <v-list nav dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in pflanzen"
                  :key="i"
                  @click="selectPflanze(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-icon large>
                    mdi-pencil
        </v-icon> -->
      </v-col>
    </v-row>
    <!-- <v-card> -->
    <!-- <v-card-title>
              <span class="text-h5">das hier geht noch nicht - macht gar nix!</span>
            </v-card-title> -->
    <v-card-text>
      <v-container>
        <v-row>
          <!-- <v-col sm="6" md="4">
                    <v-text-field label="Grundstuck"></v-text-field>
                  </v-col> -->
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="grundstuecke"
              label="Grundstück"
              v-model="grundstueck"
              v-on:change="changeGrundstueck(grundstueck)"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="4">
            <v-text-field
              v-model="beet"
              type="number"
              label="Beet"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="4">
            <v-text-field v-model="section" label="Bereich"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="4">
            <v-text-field
              v-model="menge"
              type="number"
              label="Menge"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="4">
            <v-text-field v-model="bemerkung" label="Bemerkung"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="4">
            <!-- <v-text-field label="Datum"></v-text-field> -->
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datum"
                  label="Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="datum"
                @input="menuDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- <v-row>
                  <v-col sm="6" md="4">
                    <v-text-field label="Pflanze"></v-text-field>
                  </v-col>
                </v-row> -->
        <!-- <v-row>
                  <v-list nav dense>
                    <v-list-item-group color="primary">
                      <v-list-item v-for="(item, i) in list" :key="i" @click="">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-row> -->
      </v-container>
      <!-- <small>*indicates required field</small> -->
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="goBack"> Back </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="saveNewPos"
        :disabled="saveButtonDisabled"
      >
        Save
      </v-btn>
    </v-card-actions>
    <!-- </v-card> -->

    <!-- <v-banner elevation="0">Grundstück:</v-banner> -->
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "navigateBeet",

  data() {
    return {
      pflanze: { name: "" },
      dialog: true,
      beet: null,
      menge: null,
      menuDate: false,
      saveButtonDisabled: true,
      pflanzen: [],
      grundstueck: null,
      searchString: "",
      section: "",
      bemerkung: "",
      datum: null,
      beetNotSet: true,
      grundstuecke: ["asd"],
      grundstuecke_with_id: [{ name: "name0" }, { name: "name1a" }],
    };
  },
  methods: {
    changeGrundstueck(g) {
      if (this.pflanze["name"] != "") {
        if (this.beet != null) {
          this.saveButtonDisabled = false;
        }
      }
    },
    loadGrundstuecke() {
      axios.get(this.$hostname + "/getGrundstuecke.php").then((response) => {
        this.grundstuecke = response.data.map(function (item) {
          return item.name;
        });
        this.grundstuecke_with_id = response.data;
        // console.log(1);
      });
      // .then((response) => (console.log(response.data.map(function (item) {
      //   return item.name;
      // }))));

      // this.gundstueckliste=this.list.filter(function (item) {
      //   return currentElement.name;
      // });
      // console.log(this.list);
      // axios.get('http://localhost:80/stauden_backend_php/getGrundstuecke.php').then(response => (console.log(response.data)));
    },
    goBack() {
      if (this.grundstueck != null) {
        if (this.beet != null && this.beet != "") {
          var gID = this.grundstuecke_with_id.find(
            (item) => item.name === this.grundstueck
          )["ID"];

          this.$router.push({
            name: "beetView",
            params: {
              grundstuck: gID,
              grundstuckName: this.grundstueck,
              beet: this.beet,
            },
          });
          // console.log({
          //     grundstuck: this.grundstueck,
          //     grundstuckName: this.grundstuecke[this.grundstueck],
          //     beet: this.beet})
        }
      }
      // this.$router.go(-1)
    },
    loadSearch() {
      // console.log(axios)

      // axios.get('http://webcode.me').then(resp => {
      //     console.log(resp.data);
      // });
      axios
        .get(
          this.$hostname +
            "/plantSearch.php?searchString=" +
            encodeURIComponent(this.searchString)
        )
        .then((response) => (this.pflanzen = response.data));
      // axios.get('http://localhost:80/stauden_backend_php/plantSearch.php?searchString='+encodeURIComponent(this.searchString)).then(response => (console.log(response.data)));
      // this.plants=[]
    },
    selectPflanze(item) {
      this.dialog = false;
      this.pflanze = item;
      // console.log(this.grundstueck)
      if (this.grundstueck != null) {
        if (this.beet != null) {
          this.saveButtonDisabled = false;
        }
      }
    },

    saveNewPos() {
      console.log(this.grundstuecke_with_id);
      const params = new URLSearchParams();
      params.append("stauID", this.pflanze["ID"]);

      var gID = this.grundstuecke_with_id.find(
        (item) => item.name === this.grundstueck
      )["ID"];

      params.append("grundstueckID", gID);
      params.append("stauID", this.pflanze["ID"]);
      params.append("beet", this.beet);
      params.append("datum", this.datum);
      params.append("bemerkung", this.bemerkung);
      params.append("menge", this.menge);
      
      
      var sect=this.section
      if(isNaN(this.section)){
        this.section=this.section.trim()
        sect = parseInt(this.section, 36) - 10;
        // console.log(sect)
      }
      console.log(this.pflanze.ID)
      params.append("section", sect);
      // console.log(params);
      axios
        .post(this.$hostname + "/newPosition.php", params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          // console.log(response.data);
          // console.log({
          //   grundstuck: gID,
          //   grundstuckName: this.grundstueck,
          //   beet: this.beet,
          // });
          
          this.$router.push({
            name: "beetView",
            params: {
              grundstuck: gID,
              grundstuckName: this.grundstueck,
              beet: this.beet,
              newStaude: this.pflanze
            },
          });
        });
    },
  },
  watch: {
    beet: function () {
      this.saveButtonDisabled = true;
      if (this.grundstueck != null) {
        if (this.beet != null && this.beet != "") {
          if (this.pflanze["name"] != "") {
            this.saveButtonDisabled = false;
          }
        }
      }
    },
    searchString: function () {
      if (this.searchString.length > 2) {
        this.loadSearch();
      } else {
        this.pflanzen = [];
      }
    },
  },
  beforeMount: function () {
    this.loadGrundstuecke();
    let myDate = new Date();
    const offset = myDate.getTimezoneOffset();
    myDate = new Date(myDate.getTime() - offset * 60 * 1000);
    this.datum = myDate.toISOString().split("T")[0];

    if (
      this.$route.params.hasOwnProperty("beet") &&
      this.$route.params.hasOwnProperty("grundstueck") &&
      this.$route.params.hasOwnProperty("section")
    ) {
      this.beet = this.$route.params.beet;
      this.grundstueck = this.$route.params.grundstueck;
      this.section = this.$route.params.section;
    }
  },
};
</script>
