<template>
  <div>
    
    <div class="p-5 text-center bg-light">
    
      <h1 class="mb-3">Aufträge</h1>
      
    </div>

            <v-row align="center"
             justify="center"
             v-for="(item, i) in list"
          :key="i">
          
            <v-col cols=1>
              <!-- <v-icon>mdi-plus-circle </v-icon> -->
             <v-checkbox
              v-model="item.bearbeitet"
              color="success"
              hide-details
              @click=changestate(item)
            ></v-checkbox>
            </v-col>    
            <v-col cols=10 align="center"
             justify="center">
            
            <v-btn elevation="1" color="#e0f4ff" @click="gotoAuftrag(item)">
              {{item.name}}
            </v-btn>
            
            </v-col cols=1>
              <v-icon @click="deleteItem(item)"> mdi-trash-can-outline</v-icon>
            <v-col>
            </v-col>
            </v-row>


        <v-btn block elevation="2" 
        color="#ffc6bd"
        @click="alleLoeschen">
          <v-icon>mdi-trash-can-outline </v-icon>
          Alle App-Aufträge löschen!
        </v-btn>

  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "navigateBeet",

  data() {
    return {
      dialog: false,
      list: [],
    };
  },
  methods: {
    loadAuftraege() {
      axios
        .get(this.$hostname + "/getAuftraege.php")
        .then((response) => (this.list = response.data));
      // axios.get('http://localhost:80/stauden_backend_php/getGrundstuecke.php').then(response => (console.log(response.data)));
    },
    alleLoeschen() {
        if (confirm("Alle Aufträge aus der App löschen?")) {
        axios
          .get(this.$hostname + "/alleAuftraegeLoeschen.php")
          .then(response => console.log(response.data));
        this.list=[];
      }

    },
    deleteItem(item){
        // console.log(item)
         if (confirm("Auftrag "+item.name+" löschen?")) {
        axios
          .get(this.$hostname + "/deleteAuftrag.php?ID=" + item["ID"])
          .then((response) => console.log(response.data));
         
         for( var i = 0; i < this.list.length; i++){     
            if ( this.list[i]["ID"] == item["ID"]) { 
                this.list.splice(i, 1); 
            }
            }
         }
    },
    gotoAuftrag(item) {
        console.log(item);
      this.$router.push({
        name: "auftragsView",
        params: {
          id: item["ID"],
          name: item["name"],
          bearbeitet:item["bearbeitet"],
          datum: item["datum"]
        },
      });
    },
    
    changestate(item){
        console.log(item);
        console.log(this.list);
    },
  },
  
  watch: {
    currBeet: function () {
      if (this.currBeet != null) {
        if (this.currBeet.length > 0) {
          // console.log("asdasd");
          // console.log(this.currBeet);
          this.beetNotSet = false;
        } else {
          this.beetNotSet = true;
        }
      } else {
        this.beetNotSet = true;
      }
    },
  },
  beforeMount: function () {
    this.loadAuftraege();
  },
};
</script>
