<template>
  <div id="app">
    <v-row>
      <v-col cols="9">
        <v-card elevation="0">{{ grundstuckName }} Beet {{ beet }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-btn @click="newPos">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="drag">
      <draggable
        :options="{ delay: 600 }"
        :section="list.upvotes"
        @update="onUpdate"
      >
        <!-- <div v-for="section in list" :key="section.section"
          > -->
        <transition-group>
          <div v-for="element in list" :key="element.posID">
            <v-expansion-panels>
              <v-expansion-panel
                v-bind:style="{ backgroundColor: element.col }"
              >
                <v-expansion-panel-header  class="font-weight-bold"
                 v-if="element.ID==newStaude.ID">
                  {{ element.name }}
                </v-expansion-panel-header>
                
                <v-expansion-panel-header v-else>
                   {{ element.name }}
                </v-expansion-panel-header>
                
                <v-expansion-panel-content>
                  <v-list-item-subtitle
                    v-text="'Menge: ' + element.menge"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="'Datum: ' + element.datum"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="element.bemerkung != ''"
                    v-text="'Bem.: ' + element.bemerkung"
                  ></v-list-item-subtitle>
                  <v-row>
                    <v-col sm="6" md="4" cols="3">
                      <v-icon medium @click="deleteId(element)">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-col>
                    <v-col sm="6" md="4" cols="3">
                      <v-icon medium @click="editPosition(element)">
                        mdi-pencil
                      </v-icon>
                    </v-col>
                  </v-row>
                  <!-- <v-icon large @click="showEditPosition(element)">
                    mdi-pencil
                  </v-icon> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </transition-group>
        <!-- </div> -->
      </draggable>
    </div>

    <v-btn elevation="1" block @click="newSection">neuer Bereich</v-btn>

     <v-dialog v-model="posDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>{{ position.name }}:</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="4">
                <p>Menge:</p>
              </v-col>
              <v-col sm="6" md="4" cols="8">
                <v-text-field
                  type="number"
                  :label="position.menge.toString()"
                  v-model="newPosition.menge"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newPosition.datum"
                      :label="position.datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newPosition.datum"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col sm="6" md="4" cols="3">
                              <p>Dat.:</p>
                            </v-col>
                            <v-col sm="6" md="4" cols="9">
                              <v-text-field :label=position.datum
                              v-model=newPosition.datum></v-text-field>
                            </v-col> -->
            </v-row>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="3">
                <p>Bem.:</p>
              </v-col>
              <v-col sm="6" md="4" cols="9">
                <v-text-field
                  :label="position.bemerkung"
                  v-model="newPosition.bemerkung"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="posDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="savePosition"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import Vue from "vue";
// import editPosition from "../components/editPosition.vue";

export default {
  name: "StaudenSuche",

  data() {
    return {
      dialog: false,
      posDialog: false,
      beet: null,
      grundstuckName: null,
      grundstuck: null,
      newNam: "",
      // oldNam: "",
      menuDate: false,
      posDialog: false,
      stauDialog: false,
      staude: [],
      newStaude: {"ID":-1},
      plants: [],
      position: {
        section: null,
        menge: 0,
        bemerkung: null,
        datum: null,
        name: null,
      },
      newPosition: {
        section: null,
        menge: 0,
        bemerkung: null,
        datum: null,
        name: null,
        stauID: null,
      },
      list: [],
    };
  },
  methods: {
    newPos() {
      // this.$route.params.hasOwnProperty("beet") &&
      // this.$route.params.hasOwnProperty("grundstueck") &&
      // this.$route.params.hasOwnProperty("section")
      this.$router.push({
        name: "newPosition",
        params: {
          grundstueck: this.grundstuckName,
          section: 0,
          beet: this.beet,
        },
      });
    },

    showEditPosition(pfl) {
      this.position = pfl;
      this.posDialog = true;
    },
    newSection() {
      var i = this.list.length - 1;
      console.log(this.list[i]);

      var col1 = "#c7ffcb";
      var col2 = "#cac7ff";
      axios
        .get(
          this.$hostname +
            "/increaseSectionOfPosition.php?posID=" +
            encodeURIComponent(this.list[i]["posID"])
        )
        .then((response) => console.log(response.data));
      this.list[i]["sectionID"] += 1;
      this.list[i]["section"] = String.fromCharCode(
        this.list[i]["section"].charCodeAt() + 1
      );
      if (this.list[i]["col"] == col2) {
        this.list[i]["col"] = col1;
      } else {
        this.list[i]["col"] = col2;
      }
    },
    onUpdate(e) {
      var y = e.newDraggableIndex;
      var x = e.oldDraggableIndex;

      const params = new URLSearchParams();
      params.append("posID", this.list[x]["posID"]);
      params.append("newPosID", this.list[y]["posID"]);

      axios
        .post(this.$hostname + "/movePlant.php", params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log("response" + response.data);
        });

      if (x < y) {
        this.list.splice(y + 1, 0, { ...this.list[x] });
        this.list.splice(x, 1);
        // console.log("a")
      } else {
        this.list.splice(y, 0, { ...this.list[x] });
        this.list.splice(x + 1, 1);
      }

      // console.log(this.list[y-1]["sectionID"]);
      // console.log(this.list[y]["sectionID"]);
      if (y > 0) {
        if (this.list[y]["sectionID"] < this.list[y - 1]["sectionID"]) {
          this.list[y]["sectionID"] = this.list[y - 1]["sectionID"];
          this.list[y]["section"] = this.list[y - 1]["section"];
          this.list[y]["col"] = this.list[y - 1]["col"];
        }
      }

      if (y < this.list.length - 1) {
        if (this.list[y]["sectionID"] > this.list[y + 1]["sectionID"]) {
          this.list[y]["sectionID"] = this.list[y + 1]["sectionID"];
          this.list[y]["section"] = this.list[y + 1]["section"];
          this.list[y]["col"] = this.list[y + 1]["col"];
        }
      }

      // if(this.list.length>y){
      //   secAfter=this.list[y+1]["sectionID"];
      // }

      // console.log(this.list);
    },
    loadBeet() {
      axios
        .get(
          this.$hostname +
            "/plantsOfBeet.php?beet=" +
            encodeURIComponent(this.beet) +
            "&grund=" +
            encodeURIComponent(this.grundstuck)
        )
        .then((response) => (this.list = response.data));
      // axios.get(this.$hostname+'/plantsOfBeet.php?beet='+encodeURIComponent(1)+"&grund="+encodeURIComponent(1)).then(response => (this.list = response.data));
      // console.log(this.list);
    },
    editPosition(position) {
      // console.log(pfl.name);
      // this.staude = pfl;
      this.position = { ...position };
      this.newPosition = { ...position };
      let myDate = new Date();
      const offset = myDate.getTimezoneOffset();
      myDate = new Date(myDate.getTime() - offset * 60 * 1000);
      this.newPosition.datum = myDate.toISOString().split("T")[0];
      this.posDialog = true;
    },
    savePlant() {
      this.stauDialog = false;
    },
    savePosition() {
      for (var i = 0; i < this.plants.length; i++) {
        var pl = this.plants[i];
        for (var i2 = 0; i2 < pl.positions.length; i2++) {
          var pos = pl.positions[i2];
          if (pos.posID == this.newPosition.posID) {
            this.plants[i].positions[i2] = { ...this.newPosition };
          }
        }
      }
      const params = new URLSearchParams();
      // console.log(this.newPosition);

      for (let key in this.newPosition) {
        params.append(key, this.newPosition[key]);
      }
      axios
        .post(this.$hostname + "/updatePosition.php", params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          this.loadBeet();
        });
      this.posDialog = false;
    },
    showpflanzenRename(evt, item) {
      evt.cancelBubble = true;
      this.stauDialog = true;
    },
    deleteId(id) {
      if (confirm("Standort löschen?")) {
        // axios.post('http://localhost:80/stauden_backend_php/deletePosition.php', { posID: id})
        //   .then(response => console.log(response));
        console.log(id);
        axios
          .get(this.$hostname + "/deletePosition.php?posID=" + id["posID"])
          .then((response) =>  this.loadBeet());
      }
    },
  },
  beforeMount() {
    if (
      this.$route.params.hasOwnProperty("beet") &&
      this.$route.params.hasOwnProperty("grundstuck") &&
      this.$route.params.hasOwnProperty("grundstuckName")
    ) {
      this.beet = this.$route.params.beet;
      this.grundstuck = this.$route.params.grundstuck;
      this.grundstuckName = this.$route.params.grundstuckName;
      
      if (
      this.$route.params.hasOwnProperty("newStaude")
      ) {
        this.newStaude = this.$route.params.newStaude;
        // console.log(this.newStaudeID)
      }
      // console.log(this.newStaudeID)
      this.loadBeet();

    } else {
      this.$router.push({
        name: "navigateBeet",
      });
    }
    
  },
  components: {
    draggable,
  },
};
</script>
